<template>
  <BModal
    title="Create Fake User Level"
    id="create-new-fake-user-level"
    centered
    ok-title="Create"
    cancel-title="Dismiss"
    :ok-disabled="isLoading"
    no-close-on-backdrop
    header-bg-variant="success"
    title-class="text-white"
    ok-variant="success"
    no-close-on-ok
    @hidden="resetModal"
    @ok.prevent="createNewFakeUserLevel"
  >
    <BOverlay :show="isLoading">
      <BContainer fluid>
        <BFormRow>
          <BCol cols="12" class="mt-2">
            <TextInput
              inputId="levelName"
              inputLabel="Level Name"
              inputPlaceholder="Enter Your Fake Level Name"
              v-model="fakeUserPayload.name"
            ></TextInput>
            <TextInput
              inputId="minBet"
              inputLabel="Minimum Bet Amount"
              inputPlaceholder="Enter Minimum Bet Amount"
              v-model="fakeUserPayload.minBeat"
            ></TextInput>
            <TextInput
              inputId="maxBet"
              inputLabel="Maximum Bet Amount"
              inputPlaceholder="Enter Maximum Bet Amount"
              v-model="fakeUserPayload.maxBeat"
            ></TextInput>
            <TextInput
              inputId="chargeAmount"
              inputLabel="Recharge Wallet Amount"
              inputPlaceholder="Enter Recharge Wallet Amount"
              v-model="fakeUserPayload.chargeAmount"
            ></TextInput>
          </BCol>
        </BFormRow>
      </BContainer>
    </BOverlay>
  </BModal>
</template>

<script>
export default {
  emits: ["refetch"],
  data() {
    return {
      isLoading: false,
      fakeUserPayload: {
        name: null,
        minBeat: 0,
        maxBeat: 0,
        chargeAmount: 0,
      },
    };
  },
  methods: {
    async createNewFakeUserLevel() {
      try {
        this.isLoading = true;
        const _this = this;
        const createFakeUserLevel = new CreateFakeUserLevel(_this);
        createFakeUserLevel.setRequestParamDataObj(_this.fakeUserPayload);
        await createFakeUserLevel.fetch((res) => {
          if (res.isSuccess) {
            _this.$bvModal.hide("create-new-fake-user-level");
            _this.$toast({
              component: ToastificationContent,
              position: "bottom-center",
              props: {
                title: "Created!",
                icon: "CheckIcon",
                variant: "success",
                text: "Fake User Level Created successfully.",
              },
            });
            _this.$emit("refetch");
          } else {
            _this.$toast({
              component: ToastificationContent,
              position: "bottom-center",
              props: {
                title: "Failed",
                icon: "CloseIcon",
                variant: "danger",
                text: res.errorMessage || "Something went wrong.",
              },
            });
          }
        });
      } catch (error) {
        console.error(error);
      } finally {
        this.isLoading = false;
      }
    },
    resetModal() {
      this.fakeUserPayload = {
        name: null,
        minBeat: 0,
        maxBeat: 0,
        chargeAmount: 0,
      };
    },
  },
  components: {
    BModal,
    BOverlay,
    BContainer,
    BCol,
    BFormRow,
    BButton,
    BAlert,
    TextInput,
  },
};
import TextInput from "@/views/components/Utilities/TextInput.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import { CreateFakeUserLevel } from "@/libs/Api/FakeUserLevels";
import {
  BModal,
  BOverlay,
  BContainer,
  BCol,
  BFormRow,
  BButton,
  BAlert,
} from "bootstrap-vue";
</script>
