<template>
  <PageWrapper :isLoading="isLoading">
    <template #title> Fake Users Levels List </template>
    <template #titleBtn>
      <BButton
        @click="createFakeUserLevel"
        :block="$store.getters['app/currentBreakPoint'] === 'xs'"
        variant="success"
      >
        + Add New Level
      </BButton>
    </template>
    <CustomTable
      v-if="fakeUsersList"
      :pageNumber="fakeUserLevelListFilters.pageNumber"
      :count="fakeUserLevelListFilters.count"
      :totalCount="totalCount"
      :tableItems="fakeUsersList"
      :tableColumns="myTableColumns"
      @changePage="changePage"
    >
      <template #index="data">
        {{
          Math.ceil(
            data.index +
              1 +
              (fakeUserLevelListFilters.pageNumber - 1) *
                fakeUserLevelListFilters.count
          )
        }}
      </template>
      <template #actions="data">
        <div class="w-100 d-flex align-items-center justify-content-between">
          <BButton
            type="button"
            variant="transparent"
            size="sm"
            @click="renderFakeUserLevelUpdateModal(data.item)"
          >
            <feather-icon icon="EditIcon" class="text-primary" size="20" />
          </BButton>
          <BButton
            type="button"
            variant="transparent"
            size="sm"
            @click="renderFakeUserLevelDeleteModal(data.item)"
          >
            <feather-icon icon="Trash2Icon" class="text-danger" size="20" />
          </BButton>
        </div>
      </template>
    </CustomTable>
    <CreateFakeUserLevelModal
      @refetch="getAllFakeUsers"
    ></CreateFakeUserLevelModal>
    <UpdateFakeUserLevelModal
      @refetch="getAllFakeUsers"
      @resetModal="resetUpdateModal"
      :defaultData="tempSelectedFakeLevel"
    ></UpdateFakeUserLevelModal>
    <DeleteFakeUserLevelModal
      @refetch="getAllFakeUsers"
      :defaultData="tempSelectedFakeLevel"
    ></DeleteFakeUserLevelModal>
  </PageWrapper>
</template>

<script>
export default {
  title: "Fake User Level List",
  mounted() {
    this.getAllFakeUsers();
  },
  data() {
    return {
      isLoading: false,
      fakeUserLevelListFilters: {
        pageNumber: 1,
        count: 10,
      },
      fakeUsersList: null,
      totalCount: null,
      myTableColumns: [
        {
          key: "index",
          label: "#",
        },

        {
          key: "name",
          label: "Name",
          formatter: (value) => value.trim() || "-",
        },

        {
          key: "minBeat",
          label: "Min Bet",
          formatter: (value) => `${value} $`,
        },

        {
          key: "maxBeat",
          label: "Max Bet",
          formatter: (value) => `${value} $`,
        },

        {
          key: "chargeAmount",
          label: "Recharge Amount",
          formatter: (value) => `${value} $`,
        },

        {
          key: "actions",
          label: "Actions",
        },
      ],
      tempSelectedFakeLevel: null,
    };
  },
  methods: {
    createFakeUserLevel() {
      this.$bvModal.show("create-new-fake-user-level");
    },
    resetUpdateModal() {
      this.tempSelectedFakeLevel = null;
    },
    renderFakeUserLevelUpdateModal(fakeLevel) {
      this.tempSelectedFakeLevel = fakeLevel;
      this.$bvModal.show("update-new-fake-user-level");
    },
    renderFakeUserLevelDeleteModal(fakeLevel) {
      this.tempSelectedFakeLevel = fakeLevel;
      this.$bvModal.show("delete-fake-user-level");
    },
    async getAllFakeUsers() {
      try {
        this.isLoading = true;
        const _this = this;
        const getAllFakeUserLevels = new GetAllFakeUserLevels(_this);
        getAllFakeUserLevels.setRequestParam(_this.fakeUserLevelListFilters);
        await getAllFakeUserLevels.fetch((res) => {
          this.fakeUsersList = res.data.data;
          this.totalCount = res.data.total;
          console.log(this.fakeUsersList);
        });
      } catch (error) {
        console.error(error);
      } finally {
        this.isLoading = false;
      }
    },
    changePage(pageNumber) {
      this.fakeUserLevelListFilters.pageNumber = pageNumber;
      this.getAllFakeUsers();
    },
  },
  components: {
    PageWrapper,
    CustomTable,
    CreateFakeUserLevelModal,
    UpdateFakeUserLevelModal,
    DeleteFakeUserLevelModal,
    BButton,
  },
};

import PageWrapper from "@/views/components/Utilities/PageWrapper.vue";
import CustomTable from "@/views/components/Utilities/CustomTable.vue";
import CreateFakeUserLevelModal from "@/views/components/FakeUserLevels/CreateFakeUserLevelModal.vue";
import DeleteFakeUserLevelModal from "@/views/components/FakeUserLevels/DeleteFakeUserLevelModal.vue";
import UpdateFakeUserLevelModal from "@/views/components/FakeUserLevels/UpdateFakeUserLevelModal.vue";
import { GetAllFakeUserLevels } from "@/libs/Api/FakeUserLevels";
import { BButton } from "bootstrap-vue";
</script>
