<template>
  <BModal
    title="Delete Fake User Level"
    id="delete-fake-user-level"
    centered
    ok-title="Delete"
    cancel-title="Dismiss"
    :ok-disabled="isLoading"
    no-close-on-backdrop
    header-bg-variant="danger"
    title-class="text-white"
    ok-variant="danger"
    no-close-on-ok
    @ok.prevent="deleteFakeUserLevel"
  >
    <BOverlay :show="isLoading">
      <BContainer fluid>
        <BCol cols="12" class="mt-2">
          <p>Are you sure you want to delete this level?</p>
        </BCol>
      </BContainer>
    </BOverlay>
  </BModal>
</template>

<script>
export default {
  emits: ["refetch"],
  props: {
    defaultData: {
      type: Object,
      required: false,
    },
  },
  methods: {
    async deleteFakeUserLevel() {
      try {
        this.isLoading = true;
        const _this = this;
        const deleteFakeUserLevel = new DeleteFakeUserLevel(_this);
        deleteFakeUserLevel.setRequestParam({
          id: this.defaultData.id,
        });
        await deleteFakeUserLevel.fetch((res) => {
          if (res.isSuccess) {
            _this.$bvModal.hide("delete-fake-user-level");
            _this.$toast({
              component: ToastificationContent,
              position: "bottom-center",
              props: {
                title: "Deleted!",
                icon: "CheckIcon",
                variant: "success",
                text: "Fake User Level Deleted Successfully.",
              },
            });
            _this.$emit("refetch");
          } else {
            _this.$toast({
              component: ToastificationContent,
              position: "bottom-center",
              props: {
                title: "Failed",
                icon: "CloseIcon",
                variant: "danger",
                text: res.errorMessage || "Something went wrong.",
              },
            });
          }
        });
      } catch (error) {
        console.error(error);
      } finally {
        this.isLoading = false;
      }
    },
  },
  components: {
    BModal,
    BOverlay,
    BContainer,
    BCol,
  },
};
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import { BModal, BOverlay, BContainer, BCol } from "bootstrap-vue";
import { DeleteFakeUserLevel } from "@/libs/Api/FakeUserLevels";
</script>
